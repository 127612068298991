import { Flex, Text } from '@chakra-ui/react';
import { ModalWrapper } from 'components/elements/wrappers';
import { Box } from 'componentsV2/elements/boxes';
import { Modal } from 'componentsV2/elements/modal';
import { Tooltip } from 'componentsV2/elements/tooltip';
import { useNavigation } from 'hooksV2/useNavigation';
import { usePendencyToIcon } from 'pagesV2/Clients/hooks/usePendencyToIcon';
import { useRemoveHomeTask } from 'pagesV2/Home/hooks/useRemoveHomeTask';
import { useState } from 'react';
import { useCommonHover } from 'shared/styles/hooks/useCommonHover';
import { colors } from 'sharedV2/colors';
import { Icons } from 'sharedV2/icons';
import { ClientPendencyTypeEnum, ClientType } from 'sharedV2/types/client';
import { CurrencyUtils } from 'utilsV2/Currency';
import { TextUtils } from 'utilsV2/Text';

type PropsType = {
  client: ClientType;
};

export const ClientCard: React.FC<PropsType> = ({ client }): JSX.Element => {
  const { redirect } = useNavigation();

  const [shouldOpenStuckMoneyModal, setShouldOpenStuckMoneyModal] =
    useState(false);

  const pendencyToIcon = usePendencyToIcon();

  const doesClientHavePendencies = client.pendencies.length > 0;

  const doesClientHaveStuckMoneyPendency =
    doesClientHavePendencies &&
    client.pendencies.filter(
      pendency => pendency.type === ClientPendencyTypeEnum.STUCK_MONEY
    ).length > 0;

  const stuckMoneyPendency = doesClientHaveStuckMoneyPendency
    ? client.pendencies.find(
        item => item.type === ClientPendencyTypeEnum.STUCK_MONEY
      )
    : undefined;

  const { mutateAsync: removeHomeTask, isLoading: isLoadingRemoveHomeTask } =
    useRemoveHomeTask();

  const onConfirmModal = async () => {
    if (!stuckMoneyPendency) return;

    await removeHomeTask(stuckMoneyPendency.id);

    setShouldOpenStuckMoneyModal(false);

    redirect(`/v2/client/${client.btgCode}`);
  };

  const handleOnCardClick = () => {
    if (doesClientHaveStuckMoneyPendency) {
      setShouldOpenStuckMoneyModal(true);
      return;
    }

    redirect(`/v2/client/${client.btgCode}`);
  };

  return (
    <>
      <Box.Opaque
        p={4}
        _hover={useCommonHover()}
        borderColor={doesClientHavePendencies ? colors.RED : colors.BLUE}
        cursor="pointer"
        gap={2}
        onClick={handleOnCardClick}
      >
        <Text
          isTruncated
          maxW="300px"
          color="black"
          fontWeight="bold"
          fontSize="lg"
        >
          {TextUtils.toCapitalize(client.name)}
        </Text>

        <Flex flexDirection="column" gap={3}>
          <Flex alignItems="center">
            <Icons.CIRCLE_DOLLAR />
            <Flex flexDirection="column" minW="150px" ml="10px">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                Dinheiro Parado
              </Text>
              <Text color="rgba(70, 70, 70, 1)">
                {client.currentBalance
                  ? CurrencyUtils.format(client.currentBalance)
                  : '-'}
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="center">
            <Icons.CHATS />
            <Flex flexDirection="column" minW="150px" ml="10px">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                Patrimônio Declarado
              </Text>
              <Text color="rgba(70, 70, 70, 1)">
                {client.netWorth?.declared
                  ? CurrencyUtils.format(client.netWorth?.declared)
                  : '-'}
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="center">
            <Icons.CIRCLE_DOLLAR />
            <Flex flexDirection="column" minW="150px" ml="10px">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                PL
              </Text>
              <Text color="rgba(70, 70, 70, 1)">
                {client.netWorth?.total
                  ? CurrencyUtils.format(client.netWorth.total)
                  : '-'}
              </Text>
            </Flex>
          </Flex>

          <Flex alignItems="center">
            <Icons.WARN />
            <Flex flexDirection="column" minW="150px" ml="10px">
              <Text fontSize="sm" color="rgba(70, 70, 70, 1)">
                Pendências
              </Text>
              <Flex gap={1}>
                {doesClientHavePendencies ? (
                  <>
                    {client.pendencies.map((pendency, index) => {
                      const pendencyIconData =
                        pendencyToIcon[pendency.type] || pendencyToIcon.default;

                      const { icon, tooltipText } = pendencyIconData;

                      return (
                        <Tooltip key={index} text={tooltipText}>
                          <Flex>{icon}</Flex>
                        </Tooltip>
                      );
                    })}
                  </>
                ) : (
                  <Text color="rgba(70, 70, 70, 1)">-</Text>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box.Opaque>

      <ModalWrapper
        isOpen={shouldOpenStuckMoneyModal}
        onClose={() => setShouldOpenStuckMoneyModal(false)}
      >
        <Modal.Root>
          <Modal.Header>
            <Modal.Title title="Dinheiro Parado" />
            <Modal.CloseIcon
              onClick={() => setShouldOpenStuckMoneyModal(false)}
            />
          </Modal.Header>

          <Flex flexDirection="column" gap={1}>
            <Text color="rgba(70, 70, 70, 1)">
              Você confirma que o dinheiro do(a) cliente {client.name} já foi
              alocado?
            </Text>
            <Text
              color="rgba(70, 70, 70, 1)"
              w="100%"
              textAlign="right"
              fontSize="sm"
            >
              Cod. BTG: {client.btgCode}
            </Text>
          </Flex>

          <Modal.Actions>
            <Modal.Action
              text="Sim"
              onClick={onConfirmModal}
              isLoading={isLoadingRemoveHomeTask}
            />
          </Modal.Actions>
        </Modal.Root>
      </ModalWrapper>
    </>
  );
};
