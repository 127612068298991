import { Icons } from 'sharedV2/icons';
import { ClientPendencyTypeEnum } from 'sharedV2/types/client';

type PendencyToIconType = Record<
  ClientPendencyTypeEnum | 'default',
  { icon: JSX.Element; tooltipText: string }
>;

export const usePendencyToIcon = (): PendencyToIconType => {
  const pendencyToIcon: PendencyToIconType = {
    [ClientPendencyTypeEnum.STUCK_MONEY]: {
      icon: <Icons.MONEY />,
      tooltipText: 'Dinheiro Parado',
    },
    [ClientPendencyTypeEnum.CLIENT_LEFT]: {
      icon: <Icons.EXIT />,
      tooltipText: 'Cliente Saiu',
    },
    [ClientPendencyTypeEnum.MISSING_ORIGIN]: {
      icon: <Icons.USER_FOCUS />,
      tooltipText: 'Cliente sem Origem',
    },
    [ClientPendencyTypeEnum.NEGATIVE_BALANCE]: {
      icon: <Icons.CHART_DOWN />,
      tooltipText: 'Cliente Negativo',
    },
    [ClientPendencyTypeEnum.BIRTHDAY]: {
      icon: <Icons.CALENDAR />,
      tooltipText: 'Aniversário',
    },
    default: {
      icon: <Icons.WARN color="#FF0000" />,
      tooltipText: 'Pendência desconhecida',
    },
  };

  return pendencyToIcon;
};
