import { MdFingerprint } from 'react-icons/md';
import { BsBuilding } from 'react-icons/bs';
import { IoNewspaperOutline } from 'react-icons/io5';
import { BiLock, BiWorld } from 'react-icons/bi';
import { AiOutlineCoffee } from 'react-icons/ai';

export const fundsProducts = [
  {
    title: 'Fundo de Liquidez',
    link: '/products/investment_funds/type/Fundo de Liquidez',
    icon: BiWorld,
  },
  {
    title: 'Fundo de Renda Fixa',
    link: '/products/investment_funds/type/Fundo de Renda Fixa',
    icon: BiWorld,
  },
  {
    title: 'Fundo Multimercado',
    link: '/products/investment_funds/type/Fundo Multimercado',
    icon: BiWorld,
  },

  {
    title: 'Fundos Alternativos',
    link: '/products/investment_funds/type/Fundos Alternativos',
    icon: BiWorld,
  },
  {
    title: 'Previdência',
    link: '/products/investment_funds/type/Previdência',
    icon: BiLock,
  },
];

export const otherProducts = [
  {
    title: 'Carteiras',
    link: '/products/portfolios',
    icon: MdFingerprint,
  },
  {
    title: 'IPO e Subscrição',
    link: '/products/ipo',
    icon: MdFingerprint,
  },
  {
    title: 'Fundos Imobiliários',
    link: '/products/real_estate_funds',
    icon: BsBuilding,
  },

  {
    title: 'COE',
    link: '/products/coe',
    icon: IoNewspaperOutline,
  },
  {
    title: 'Oferta Pública Renda Fixa',
    link: '/products/public_offering',
    icon: IoNewspaperOutline,
  },
  {
    title: 'Apresentações Gestoras',
    link: 'https://www.dropbox.com/sh/82lwo1lw45lgb22/AAAlArpUBZG03yB4HH6aMrp-a?dl=0',
    icon: IoNewspaperOutline,
  },
  {
    title: 'Apresentação de Produtos',
    link: 'https://www.dropbox.com/scl/fo/wp4bi1jhpeen2t6qqpntz/h?dl=0&rlkey=00lz5xdk01gtnetvwy2ikye6n',
    icon: IoNewspaperOutline,
  },
  {
    title: 'Reunião RV',
    link: 'https://www.dropbox.com/sh/iig7adasi9n2u6b/AACGjOtkfjyhi9XFiwEx9F6pa?dl=0',
    icon: IoNewspaperOutline,
  },
  {
    title: 'Café com Diamantes',
    link: 'https://www.dropbox.com/sh/s9p33kuu7jz5lhf/AAAzFQmcnJVTz6C3ZJD_nhEMa?dl=0',
    icon: AiOutlineCoffee,
  },
];
