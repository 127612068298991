export enum ClientTypeEnum {
  PF = 'PF',
  PJ = 'PJ',
  INVESTIDOR_ESTRANG_EXTERNO_CC5 = 'INVESTIDOR ESTRANG EXTERNO - CC5',
}

export enum ClientPendencyTypeEnum {
  STUCK_MONEY = 'stuckMoney',
  NEGATIVE_BALANCE = 'negativeBalance',
  MISSING_ORIGIN = 'missingOrigin',
  CLIENT_LEFT = 'clientLeft',
  BIRTHDAY = 'birthday',
}

export type ClientNetWorthType = {
  btgCode: string;
  createdAt: string;
  declared: number | null;
  derivatives: number | null;
  fixedIncome: number | null;
  founds: number | null;
  total: number | null;
  updatedAt: string;
  variableIncome: number | null;
  totalDeclaredByAAI: number | null;
};

export type ClientPendencyType = {
  id: string;
  btgCode: string;
  type: ClientPendencyTypeEnum;
  client: ClientType;
  created_at: string;
  updated_at: string;
};

export type ClientAdditionalInformationType = {
  id: string;
  btgCode: string;
  question: string;
  answer: string;
  createdAt: string;
  updatedAt: string;
};

export type ClientRemindersType = {
  id: string;
  btgCode: string;
  title: string;
  description: string;
  date: string;
};

export type ClientNotesType = {
  id: string;
  btgCode: string;
  content: string;
  from: string | undefined;
  createdAt: string;
  updatedAt: string;
};

export enum ClientMeetingStatusEnum {
  CREATED = 'created',
  CONCLUDED = 'concluded',
}

export enum ClientAportStatusEnum {
  CREATED = 'created',
  CONCLUDED = 'concluded',
}

export type ClientEmployeeTag = {
  id: string;
  employeeId: string;
  name: string;
};

export type ClientMeetingType = {
  id: string;
  btgCode: string;
  date: string;
  commentedAreas: string[];
  status: ClientMeetingStatusEnum;
  observations: string;
  contactType: string;
  clientSatisfaction: number;
  createdAt: string;
};

export type ClientAportType = {
  id: string;
  btgCode: string;
  date: string;
  status: string;
  description: string;
  value: number;
};

export type ClientType = {
  annualIncome: number;
  birthDate: string;
  btgCode: string;
  city: string;
  phone: string | null;
  cpf: string;
  currentBalance: number | null;
  email: string;
  occupation: string;
  origin: string | null;
  employeeId: string;
  firstAportDate: string;
  lastAportDate: string;
  maritalStatus: string;
  investorProfile: string;
  name: string;
  openAccountDate: string;
  range: string | null;
  type: ClientTypeEnum;
  tags: string[];
  netWorth: ClientNetWorthType;
  pendencies: ClientPendencyType[];
  additionalInformations: ClientAdditionalInformationType[];
  meetings: ClientMeetingType[];
  reminders: ClientRemindersType[];
  notes: ClientNotesType[];
  aports: ClientAportType[];
  contactFrequency: number | null;
  isContactFrequencyActive: boolean;
  createdAt: string;
  updatedAt: string;
  shouldCreateStuckMoneyPendency: boolean;
};

export type ClientTypeWithEmployeeName = ClientType & { employeeName: string };

export type ClientHistoryType = {
  id: string;
  btgCode: string;
  action: string;
  employeeName: string | null;
  createdAt: string;
  updatedAt: string;
};
