import { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { Box, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { differenceInMinutes } from 'date-fns/esm';
import { toast } from 'react-toastify';
import { formatToPhone } from 'brazilian-values';
import { BiCopy } from 'react-icons/bi';
import { FcCheckmark } from 'react-icons/fc';

import { LeadDetailsProps } from 'pages/Captation/interfaces/SHLeads';
import { formateDateAndTime } from 'utils/formatDate';
import { hubApiCaptation } from 'services/hubApi/classes/HubApiCaptation';
import formatValue from 'utils/formatValue';

interface LeadSHMosaicCardProps {
  lead: LeadDetailsProps;
  setSelectedLead: React.Dispatch<
    React.SetStateAction<{
      isSelected: boolean;
      leadId: string;
      thereIsTask: boolean;
    }>
  >;
  Icon: IconType;
  cardSelected: string;
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
}

interface IAction {
  actionSeq: string;
  frequencySeq: string;
  id: string;
  leadId: string;
  name: string;
  status: 'aguardando' | 'realizada' | 'não atendeu';
}

export function LeadSHMosaicCard({
  lead,
  setSelectedLead,
  Icon,
  cardSelected,
  setScrollPosition,
}: LeadSHMosaicCardProps): JSX.Element {
  const [borderColor, setBorderColor] = useState('background.400');
  const [leadActions, setLeadActions] = useState<IAction[]>([]);
  const [tooltipText, setTooltipText] = useState('Copiar telefone');
  const [showCopyIcon, setShowCopyIcon] = useState(true);
  const formattedPhone = `+55 ${
    lead.phone[0] &&
    formatToPhone(
      lead?.phone[0]?.phone.includes('55')
        ? lead?.phone[0]?.phone.substring(2)
        : lead?.phone[0]?.phone
    )
  }`;

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(lead?.phone[0]?.phone);
    setShowCopyIcon(false);
    setTooltipText('Copiado!');

    setTimeout(() => {
      setShowCopyIcon(true);
      setTooltipText('Copiar telefone');
    }, 2000);
  };

  const checkBorderColor = () => {
    const compDate =
      lead.meetings.length > 0
        ? lead.meetings[lead.meetings.length - 1].date
        : lead.created_at;

    const minutesDifference = differenceInMinutes(
      new Date(compDate),
      new Date()
    );

    if (
      (minutesDifference > 0 && minutesDifference < 30) ||
      lead.frequency === '0'
    ) {
      setBorderColor('red.500');
      return;
    }
    if (minutesDifference > 30 && minutesDifference < 120)
      setBorderColor('yellow.500');
  };

  const handleSelectedLead = () => {
    setScrollPosition(
      document.querySelector('.scrollContainer')?.scrollTop || 0
    );
    setSelectedLead({
      isSelected: true,
      leadId: lead.id,
      thereIsTask: lead.meetings.length > 0,
    });
  };

  const formatScheduledTask = (dateAndTime: string): string => {
    const formatedDateAndTime = formateDateAndTime(dateAndTime);

    return `${formatedDateAndTime}`.replace(',', ' às');
  };

  const handleDoneActionsClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    action: IAction
  ) => {
    event.stopPropagation();

    if (action.status === 'realizada') {
      toast.dark('Esta tarefa já foi concluída');
      return;
    }

    const actionToUpdate = {
      actionSeq: action.actionSeq,
      frequencySeq: action.frequencySeq,
      id: action.id,
      leadId: action.leadId,
      name: action.name,
      status: 'realizada',
    };

    try {
      await hubApiCaptation.patchLeadAction({
        ...actionToUpdate,
      });

      toast.dark('Tarefa feita!');

      const updatedLeadActions: IAction[] = leadActions.map(leadAction => {
        if (leadAction.id === actionToUpdate.id) {
          leadAction.status = 'realizada';
        }

        return leadAction;
      });

      setLeadActions(updatedLeadActions);
    } catch (error) {
      toast.dark(
        'Falha ao concluir a tarefa, tente novamente em alguns minutos'
      );
    }
  };

  useEffect(() => {
    checkBorderColor();
    setLeadActions(lead.actions);
  }, [lead]);

  return (
    <Flex
      w={['95%', '228px']}
      h="180px"
      bg="background.700"
      borderRadius="3px"
      border="1px solid"
      borderColor={cardSelected === lead.id ? 'primary.900' : borderColor}
      p={2}
      flexDir="column"
      cursor="pointer"
      justify="space-between"
      onClick={handleSelectedLead}
    >
      <Flex flexDir="column" gridGap="1">
        <Text color="white.300" fontWeight="bold" isTruncated>
          {lead.name.toUpperCase()}
        </Text>

        <Text fontSize="sm">
          {lead.status !== 'returned' &&
            lead.meetings?.length === 0 &&
            Math.abs(Number(lead.frequency)) > 0 &&
            Math.abs(Number(lead.frequency)) <= 7 &&
            'Frequência: '}
          {lead.status === 'returned' && 'R.N.A'}

          {lead.status !== 'returned' && lead.meetings?.length === 0 && (
            <Text as="strong" fontSize="sm" color="white" fontWeight="bold">
              {lead.frequency === '0' && 'HOT LEAD!'}

              {Math.abs(Number(lead.frequency)) > 0 &&
                Math.abs(Number(lead.frequency)) <= 7 &&
                lead.frequency}
            </Text>
          )}
        </Text>

        <Flex>
          <Text as="span" fontSize="sm" mb={5}>
            {lead.meetings[lead.meetings.length - 1]?.type === 'meeting' &&
              'Reunião: '}
            {lead.meetings[lead.meetings.length - 1]?.type.includes(
              'callback'
            ) && 'Retorno: '}
          </Text>

          {lead.meetings?.length > 0 ? (
            <Text as="span" fontSize="sm" color="white" ml="1">
              {formatScheduledTask(
                lead.meetings[lead.meetings.length - 1].date
              )}
            </Text>
          ) : (
            <Text as="span" fontSize="sm">
              Sem atividade agendada
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex justify="space-between" flexDirection="column">
        <Tooltip
          label={tooltipText}
          placement="top-end"
          backgroundColor="background.400"
          fontSize="xs"
          closeOnClick={false}
        >
          <Flex
            _hover={{ backgroundColor: 'background.600' }}
            py={2}
            pr={2}
            pl={1}
            borderRadius={8}
            gap={2}
            w="fit-content"
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              e.stopPropagation();
              copyTextToClipboard();
            }}
          >
            <Text as="span" color="white" fontSize="sm">
              {formattedPhone}
            </Text>

            <Flex mt={0.5}>{showCopyIcon ? <BiCopy /> : <FcCheckmark />}</Flex>
          </Flex>
        </Tooltip>
        <Text fontSize="sm" mb={1.5}>
          PL:
          <Text as="span" color="white">
            {' '}
            {lead.plMi === null
              ? 'Não informado'
              : formatValue(lead.plMi as number)}
          </Text>
        </Text>
        {lead.status !== 'returned' && lead.meetings?.length === 0 && (
          <HStack spacing={1}>
            {leadActions?.map(action => (
              <Box
                title={
                  action.status === 'aguardando'
                    ? 'Marcar tarefa como feita'
                    : 'Tarefa feita'
                }
                onClick={(
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => handleDoneActionsClick(event, action)}
              >
                <Icon
                  size={16}
                  color={action.status === 'aguardando' ? '#FF5E5E' : '#5CE046'}
                />
              </Box>
            ))}
          </HStack>
        )}
      </Flex>
    </Flex>
  );
}
